import React, { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import {
  Link as RouterLink,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";

import {
  AppBar,
  Badge,
  Button,
  IconButton,
  Toolbar,
  Tooltip,
} from "@material-ui/core";
import { Beenhere, Menu as MenuIcon, Notifications } from "@material-ui/icons";

import Logo from "./Logo";
import MainMenu from "./MainMenu";

import { useBmapi } from "../utils/bmapi-context";
import { CONSUMER_ROUTES, FEATURES } from "../utils/constants";
import styles from "../utils/styles";
import { navigation } from "../messages";
import LanguageSelector from "./LanguageSelector";

export default function TopBarConsumer({ links, logo, actions }) {
  const { bmapi, userId } = useBmapi();
  const matchMessages = useRouteMatch(CONSUMER_ROUTES.MESSAGES);
  const matchMessage = useRouteMatch(CONSUMER_ROUTES.MESSAGE);
  const matchHome = useRouteMatch(CONSUMER_ROUTES.HOME);
  const location = useLocation();
  const history = useHistory();
  const intl = useIntl();
  const classes = styles.useStyles();
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [checkins, setCheckins] = useState(false);
  const [messages, setMessages] = useState(false);

  const closeMenu = () => setIsMenuVisible(false);
  const toggleMenu = () => setIsMenuVisible(!isMenuVisible);

  const goToCheckins = () => {
    history.push(CONSUMER_ROUTES.SCAN);
  };

  const goToMessages = () => {
    history.push(CONSUMER_ROUTES.MESSAGES);
  };

  const updateMessages = useCallback(() => {
    return bmapi.getUnreadMessages().then(setMessages);
  }, [bmapi]);

  const updateCheckins = useCallback(() => {
    return bmapi
      .getUserCheckIns()
      .then((cs) => setCheckins(cs.find((c) => !c.checked_out)));
  }, [bmapi]);

  useEffect(() => {
    if (userId) {
      updateCheckins();
      updateMessages();
    }
  }, [updateCheckins, updateMessages, userId]);

  useEffect(() => {
    if (matchMessages || matchMessage) {
      const to = setTimeout(updateMessages, 1000);
      return () => clearTimeout(to);
    } else if (matchHome) {
      const to = setTimeout(updateCheckins, 1000);
      return () => clearTimeout(to);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    const to = setInterval(updateMessages, 10000);
    return () => clearInterval(to);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const to = setInterval(updateCheckins, 10000);
    return () => clearInterval(to);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AppBar position="sticky" className={classes.topBar}>
      <Toolbar>
        <div style={{ flexGrow: 1 }}>
          <Logo logo={logo} link={CONSUMER_ROUTES.HOME} />
        </div>
        {checkins && (
          <Tooltip
            title={intl.formatMessage({
              id: "common.activeCheckins",
              defaultMessage: "Checkin attivi",
            })}
            arrow
          >
            <IconButton color="inherit" onClick={goToCheckins}>
              <Beenhere />
            </IconButton>
          </Tooltip>
        )}
        {userId && bmapi.can(FEATURES.MESSAGES) && (
          <IconButton color="inherit" onClick={goToMessages}>
            <Badge badgeContent={messages?.length} color="primary">
              <Notifications />
            </Badge>
          </IconButton>
        )}
        {userId ? (
          <IconButton
            edge="end"
            color="inherit"
            aria-label="Menu"
            onClick={toggleMenu}
          >
            <MenuIcon />
          </IconButton>
        ) : (
          <React.Fragment>
            <Button component={RouterLink} to={CONSUMER_ROUTES.SIGN_IN}>
              {intl.formatMessage(navigation.enter)}
            </Button>
            <LanguageSelector />
          </React.Fragment>
        )}
      </Toolbar>
      <MainMenu
        open={isMenuVisible}
        onClose={closeMenu}
        links={links}
        actions={actions}
        logoImage={logo}
        anchor="right"
      />
    </AppBar>
  );
}
