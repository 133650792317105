import { defineMessages } from "react-intl";

export const confirm = defineMessages({
  deleteManager: {
    id: "component.manageStores.confirm",
    defaultMessage: "Rimuovere il manager {email}?",
  },
  deleteStore: {
    id: "component.manageLoops.confirm",
    defaultMessage: "Rimuovere il negozio {name}?",
  },
  deleteLocation: {
    id: "component.manageLocations.confirm",
    defaultMessage: "Rimuovere il luogo {name}?",
  },
  exitPrompt: {
    id: "component.form.exitPrompt",
    defaultMessage:
      "Ci sono modifiche non salvate. Sei sicuro di voler uscire?",
  },
});
