import {
  API,
  BUSINESS_TYPES,
  CONSUMER,
  DEFAULT_MANIFEST,
  DEV_SUBSCRIPTION,
  DOMAIN_WILDCARD,
  EMPTY_USER,
  ERRORS,
  EVENT_STATUS,
  EXPERIMENTAL_FEATURES,
  FEATURE_PERMISSION,
  HTTP,
  MANAGER,
  MESSAGE_STATUS,
  PERMISSIONS,
  RESOURCE_TYPES,
  ROLES,
  SUBSCRIPTIONS,
  TENANTS,
  USER_STATUS,
} from "./constants";
import Firebase, {
  EmailAuthProvider,
  FacebookAuthProvider,
  GoogleAuthProvider,
  TwitterAuthProvider,
} from "./firebase";
import createTheme from "./themes";
import utils, { BmapiError } from "./utils";

export default class BmarkenAPI {
  constructor(tenant, subscription, app) {
    console.group("BME");

    console.group("Loaded");
    this.allSettings = utils.createSettings(tenant, process.env.REACT_APP_ENV);
    this.app = app;
    this.tenant = tenant;
    this.subscriptionSlug = subscription;
    this.user = null;
    this.userData = null;

    this.settings = this.allSettings[this.app];

    this.isConsumerEnabled = this.isAppEnabled(CONSUMER);
    this.isManagerEnabled = this.isAppEnabled(MANAGER);
    this.language = this.settings.defaultLanguage;
    this.onAuthStateChangedFns = [];
    this.onThemeLoadFns = [];

    if (!this.isAppEnabled(this.app)) {
      if (this.app === CONSUMER && this.isManagerEnabled) {
        throw new BmapiError(ERRORS.ONLY_MANAGER_ENABLED, this.settings);
      }
      throw new BmapiError(ERRORS.APP_NOT_CONFIGURED, this.settings);
    }

    if (!this.subscriptionSlug && SUBSCRIPTIONS) {
      throw new BmapiError(ERRORS.MISS_SUBSCRIPTION, this.settings);
    }

    this.businessProfileConf = require(`../configurations/business/${this.settings.businessProfile}.json`);
    this.manifest = require(`../configurations/manifest/${this.settings.manifest}.json`);
    this.firebase = new Firebase(
      require(`../configurations/firebase/${this.settings.firebase}.json`),
      this.appKey,
      this.settings.persistance,
      (user) => this.setFirebaseUser(user),
      this.settings.analytics
    );
    this.themeConf = utils.createThemeConf(this.settings.theme, this.app);
    this.theme = {};
    this.title = this.getTitle();
    console.groupEnd();

    console.info(`Tenant: ${tenant}`);
    console.info(`Subscription: ${subscription || "disabled"}`);
    console.info(`App: ${app}`);
    console.info(`Env: ${process.env.REACT_APP_ENV}/${process.env.NODE_ENV}`);
    console.info(`UI version: ${process.env.REACT_APP_GIT_SHA}`);
    console.info(`Project: ${this.settings.firebase}`);
    console.info(`Stats: ${this.settings.analytics ? "On" : "Off"}`);
    this.settings.experimental
      ? console.warn(
          `Experimental: On [${EXPERIMENTAL_FEATURES.filter(
            (f) => !!this.settings[f]
          ).join(", ")}]`
        )
      : console.info("Experimental: Off");
    console.groupEnd();

    return [
      this,
      this.getMe()
        .then(() =>
          this.subscriptionSlug && this.subscriptionSlug !== DEV_SUBSCRIPTION
            ? this.getSubscriptionsBySlug()
            : []
        )
        .then((subs) => {
          if (
            SUBSCRIPTIONS &&
            (!Array.isArray(subs) || subs.length === 0) &&
            (!JSON.parse(process.env.REACT_APP_DEV_SUBSCRIPTION) ||
              this.subscriptionSlug !== DEV_SUBSCRIPTION)
          ) {
            throw new BmapiError(ERRORS.WRONG_SUBSCRIPTION, this.settings);
          }
          if (SUBSCRIPTIONS) {
            this.subscription = subs[0];
            this.themeConf = this.getThemeConf();
          }
          this.theme = createTheme(this.themeConf, app);
        })
        .then(() => this.triggerAuthStateChanged()),
    ];
  }

  getTitle() {
    return `${this.manifest.name || DEFAULT_MANIFEST.name}${
      process.env.NODE_ENV !== "production" ? ` [${process.env.NODE_ENV}]` : ""
    }`;
  }

  getSubscriptionLogos() {
    const sub = this.subscription;
    return this.isConsumer()
      ? {
          logo: sub.logo_small || sub.logo_big,
          logoLogin: sub.logo_big,
        }
      : {
          logo: sub.logo_small_manager || sub.logo_big_manager || sub.logo_big,
          logoLogin: sub.logo_big_manager || sub.logo_big,
        };
  }

  getThemeConf() {
    return this.subscription
      ? {
          ...this.themeConf,
          ...{
            primary: this.subscription.primary_color,
            ...this.getSubscriptionLogos(),
          },
        }
      : this.themeConf;
  }

  setLanguage(lang) {
    this.language = lang;
  }

  get businessProfile() {
    return {
      ...this.businessProfileConf,
      ...(this.businessProfileConf[this.language] || {}),
    };
  }

  isAppEnabled(app) {
    return this.allSettings[app].clientId && this.allSettings[app].enabled;
  }

  setFirebaseUser() {
    // localStorage.removeItem(this.appKey);
    // this.user = user;
    return this.triggerAuthStateChanged();
  }

  can(feature) {
    const { role, permissions = [] } = this.getUserInfo();

    return (
      !!this.settings[feature] &&
      (!EXPERIMENTAL_FEATURES.includes(feature) ||
        !!this.settings.experimental) &&
      (role === ROLES.USER ||
        !FEATURE_PERMISSION[feature] ||
        (permissions || []).includes(FEATURE_PERMISSION[feature]))
    );
  }

  get customCSS() {
    return this.themeConf.customCSS
      ? this.themeConf.customCSS.includes("://")
        ? this.themeConf.customCSS
        : `${this.tenantStaticUrl}${this.themeConf.customCSS}`
      : false;
  }

  logo({ small = false } = {}) {
    const logo =
      this.themeConf[small ? "logo" : "logoLogin"] || this.settings.logo;
    return logo?.includes("://") ? logo : `${this.tenantStaticUrl}${logo}`;
  }

  get tenantStaticUrl() {
    return `/static/tenants/${this.tenant}/`;
  }

  isConsumer() {
    return this.app === CONSUMER;
  }

  isManager() {
    return this.app === MANAGER;
  }

  getFirebaseAuth() {
    return this.firebase.app.auth();
  }

  logEvent(event) {
    this.firebase.log(event);
  }

  getJsonHeaders() {
    return {
      Accept: "application/json",
      "Content-Type": "text/plain",
    };
  }

  apiFetch(
    url,
    {
      params = {},
      query = {},
      method = HTTP.GET,
      body = null,
      headers,
      json = true,
    } = {}
  ) {
    const fullUrl = utils.composeUrl(
      url.includes("://") ? url : `${this.settings.apiUrl}${url}`,
      {
        ...params,
        business: params.business || this.getUserInfo().business.id,
        subscription: this.subscription ? this.subscription.id : "",
        tenant: params.tenant || this.settings.tenantId,
        "tenant-name": this.tenant,
        user: params.user || this.getUserInfo().user_id,
      },
      { ...query, tenant: this.settings.tenantId }
    );

    return utils
      .fetch(
        fullUrl,
        method,
        json && body ? JSON.stringify(body) : body,
        headers || this.getJsonHeaders()
      )
      .catch((error) => {
        if (error.httpCode === 401) return this.clean().then(() => undefined);
        throw error;
      });
  }

  plainGet(url, config = {}) {
    return this.apiFetch(url, { ...config, headers: config.header || {} });
  }

  apiGet(url, config = {}) {
    return this.apiFetch(url, { ...config, method: HTTP.GET });
  }

  apiPost(url, config = {}) {
    return this.apiFetch(url, { ...config, method: HTTP.POST });
  }

  apiPut(url, config = {}) {
    return this.apiFetch(url, { ...config, method: HTTP.PUT });
  }

  apiDelete(url, config = {}) {
    return this.apiFetch(url, { ...config, method: HTTP.DELETE });
  }

  get appKey() {
    return `${this.tenant}-${this.settings.uniqueSignIn ? "app" : this.app}`;
  }

  get userDataKey() {
    return `${this.appKey}-${this.getUserInfo().user_id}`;
  }

  get tenantDataKey() {
    return `${this.appKey}-data`;
  }

  needsVerification() {
    return (
      this.user &&
      this.isConsumer() &&
      this.user.email &&
      !this.user.email.includes(DOMAIN_WILDCARD) &&
      this.settings.unverified &&
      !this.getUserInfo().token_info.email_verified
    );
  }

  needsPrivacy() {
    return (
      this.userData &&
      this.settings.profileVersion !== this.userData.profile_active
    );
  }

  get userStatus() {
    const logged = this.getUserInfo().user_id;
    const needsPrivacy = this.needsPrivacy();

    if (logged && needsPrivacy) return USER_STATUS.ACTIVATION;
    if (logged && !needsPrivacy) return USER_STATUS.LOGGED;
    return USER_STATUS.ANONYMOUS;
  }

  getUserInfo() {
    return utils.getData(this.appKey, EMPTY_USER);
  }

  setUserInfo(userInfo) {
    return utils.setData(this.appKey, userInfo, EMPTY_USER);
  }

  removeUserInfo() {
    this.user = null;
    this.userData = null;
    localStorage.removeItem(this.appKey);
  }

  getUserData() {
    return utils.getData(this.userDataKey);
  }

  setUserData(data) {
    return utils.setData(this.userDataKey, data);
  }

  getTenantData() {
    return utils.getData(this.tenantDataKey);
  }

  setTenantData(data) {
    return utils.setData(this.tenantDataKey, data);
  }

  setCallbackUrl(signinCallbackUrl) {
    return this.setTenantData({ signinCallbackUrl });
  }

  validateEmail(email) {
    return utils.validateEmail(email);
  }

  validatePassword(password) {
    return password.length >= 6;
  }

  validateRoles(roleWanted, userRole) {
    return utils.validateRoles(roleWanted, userRole);
  }

  validateUUID(id) {
    return utils.validateUUID(id);
  }

  createBg(seed, color) {
    return utils.createBg(seed, color || this.themeConf.primary);
  }

  clean() {
    this.removeUserInfo();
    return this.triggerAuthStateChanged().then(() =>
      this.setTenantData({ logout: true })
    );
  }

  logout() {
    return this.apiPost(API.SIGNOUT)
      .then(() => this.clean())
      .catch(console.error);
  }

  saveToken(firebase_token_id) {
    const token_info = utils.parseTokenId(firebase_token_id);

    this.setUserInfo({
      firebase_token_id,
      role: token_info.r,
      token_info,
      user_id: token_info.bme_id,
    });
    return token_info;
  }

  checkIfLoggedIn() {
    return this.user?.id ? this.getUserInfo() : false;
  }

  async doCreateUserWithEmailAndPassword(email, password) {
    return this.getFirebaseAuth()
      .createUserWithEmailAndPassword(email, password)
      .then(() => {
        this.getFirebaseAuth().currentUser.sendEmailVerification();
        return this.isConsumer() && this.settings.unverified
          ? this.doSignInWithEmailAndPassword(email, password)
          : this.logout();
      });
  }

  doPasswordReset(email) {
    return this.getFirebaseAuth().sendPasswordResetEmail(email);
  }

  async doPasswordUpdate(oldPassword, newPassword) {
    const credential = EmailAuthProvider.credential(
      this.getUserInfo().token_info.email,
      oldPassword
    );

    return this.getFirebaseAuth()
      .currentUser.reauthenticateWithCredential(credential)
      .then(() =>
        this.getFirebaseAuth()
          .currentUser.updatePassword(newPassword)
          .then(() => this.doRefreshToken())
      );
  }

  handleVerifyEmail(actionCode) {
    return this.getFirebaseAuth().applyActionCode(actionCode);
  }

  getResetPasswordEmail(actionCode) {
    return this.getFirebaseAuth().verifyPasswordResetCode(actionCode);
  }

  handleResetPassword(actionCode, newPassword) {
    return this.getFirebaseAuth().confirmPasswordReset(actionCode, newPassword);
  }

  getCurrentToken(refresh = false) {
    return this.getFirebaseAuth().currentUser
      ? this.getFirebaseAuth().currentUser.getIdToken(refresh)
      : this.logout();
  }

  async doRefreshToken() {
    return this.getCurrentToken(true)
      .then((t) => this.saveToken(t))
      .then(() => {
        const token = this.getUserInfo().firebase_token_id;
        return this.apiPost(API.COOKIE, {
          headers: { "Content-Type": "text/plain" },
          body: {
            Authorization: `Bearer ${token}`,
            client_id: this.settings.clientId,
          },
        });
      })
      .then(() => this.getMe());
  }

  async checkVerification(user, forceEmailVerified = false) {
    const emailVerified = forceEmailVerified || user.emailVerified;
    if (
      this.isManager() &&
      !emailVerified &&
      !this.settings.unverified &&
      !user.email.includes(DOMAIN_WILDCARD)
    ) {
      this.logout();
      throw new Error(ERRORS.USER_NOT_VERIFIED);
    } else if (
      this.isConsumer() &&
      !emailVerified &&
      user.email &&
      !user.email.includes(DOMAIN_WILDCARD)
    ) {
      if (!this.settings.unverified) {
        this.logout();
        throw new Error(ERRORS.USER_NOT_VERIFIED);
      } else if (
        typeof this.settings.unverified === "number" &&
        this.settings.unverified <
          (new Date() - new Date(user.metadata.creationTime)) / 864e5
      ) {
        this.logout();
        throw new Error(ERRORS.VERIFICATION_TIME_EXPIRED);
      }
    }
  }

  getCustomToken() {
    const { clientId, tenantId } = this.settings;
    if (!clientId) throw new Error(ERRORS.CLIENTID_NOT_FOUND);

    return this.apiPost(API.CUSTOM_SIGNUP, {
      body: { client_id: clientId, tenant_id: tenantId },
    })
      .then((res) => res.token)
      .catch((e) => {
        this.logout();
        throw e;
      });
  }

  async completeSignIn(token) {
    return this.signup(token)
      .then(() => this.doRefreshToken())
      .then(() => this.triggerAuthStateChanged());
  }

  async doSignInWithEmailAndPassword(email, password) {
    this.logginIn = true;
    return this.getFirebaseAuth()
      .signInWithEmailAndPassword(email, password)
      .then((credentials) => this.checkVerification(credentials.user))
      .then(() => this.getCurrentToken())
      .then((token) => this.completeSignIn(token))
      .then(() => (this.logginIn = false));
  }

  doSignInWithCustomToken() {
    this.logginIn = true;
    return this.getCustomToken()
      .then((token) => this.getFirebaseAuth().signInWithCustomToken(token))
      .then((credentials) => this.checkVerification(credentials.user))
      .then(() => this.getCurrentToken())
      .then((token) => this.completeSignIn(token))
      .then(() => (this.logginIn = false));
  }

  async doSocialSignIn() {
    this.logginIn = true;
    return this.getCurrentToken()
      .then((token) => this.completeSignIn(token))
      .then(() => (this.logginIn = false));
  }

  signup(token_id) {
    this.logEvent("signup");
    if (!this.settings.clientId) throw new Error(ERRORS.CLIENTID_NOT_FOUND);

    return this.apiPost(API.SIGNUP, {
      body: { client_id: this.settings.clientId, token_id },
    })
      .then(() => token_id)
      .catch((e) => {
        this.logout();
        throw e;
      });
  }

  getSocialSignInOptions() {
    let signInOptions = [];

    if (this.settings.google) {
      signInOptions.push({
        provider: GoogleAuthProvider.PROVIDER_ID,
        scopes: ["email"],
        customParameters: { prompt: "select_account" },
      });
    }

    if (this.settings.facebook) {
      signInOptions.push({
        provider: FacebookAuthProvider.PROVIDER_ID,
        scopes: ["public_profile", "email"],
        customParameters: { prompt: "select_account" },
      });
    }

    if (this.settings.twitter) {
      signInOptions.push(TwitterAuthProvider.PROVIDER_ID);
    }

    return signInOptions;
  }

  getSocialSignInConfig(onSuccess, onFailure) {
    return {
      signInFlow: "redirect",
      signInOptions: this.getSocialSignInOptions(),
      callbacks: {
        signInSuccessWithAuthResult: onSuccess,
        signInFailure: onFailure,
      },
    };
  }

  onAuthStateChanged(fn) {
    this.onAuthStateChangedFns.push(fn);
    return () => {
      this.onAuthStateChangedFns = this.onAuthStateChangedFns.filter(
        (f) => f !== fn
      );
    };
  }

  isFacebook(token) {
    return (
      token &&
      token.firebase &&
      token.firebase.sign_in_provider === "facebook.com"
    );
  }

  loadSubPermissions() {
    return (this.subscription
      ? this.getUserSubscriptionPermissions()
      : Promise.resolve([])
    ).then((subPermissions) => this.setUserInfo({ subPermissions }));
  }

  async triggerAuthStateChanged() {
    const user = this.checkIfLoggedIn();
    const trigger = (user) =>
      Promise.all(this.onAuthStateChangedFns.map((fn) => fn(user)));

    return !user.user_id
      ? trigger(user)
      : this.loadUserData()
          .then(() => this.loadSubPermissions())
          .then(() => this.loadBusiness())
          .then(() => trigger(this.getUserInfo()));
  }

  setTenantLanguage(language) {
    this.language = language;
    this.setTenantData({ language });
    return this.triggerAuthStateChanged();
  }

  saveUser(
    {
      birthday = "",
      direct_marketing = false,
      fiscal_code = "",
      gender = "",
      indirect_marketing = false,
      language = "",
      last_name = "",
      metadata = {},
      mobile = "",
      name = "",
      privacy = false,
      rules = false,
    } = {},
    user
  ) {
    return this.apiPost(API.USER_PROFILE, {
      params: { user },
      body: {
        birthday,
        direct_marketing,
        fiscal_code,
        gender,
        indirect_marketing,
        language,
        last_name,
        metadata,
        mobile,
        name,
        privacy,
        rules,
      },
    })
      .then(() => this.loadUserData())
      .then(() => this.triggerAuthStateChanged());
  }

  getUserBusiness() {
    return this.apiGet(API.GET_USER_BUSINESS).then((bs) => {
      return this.subscription
        ? bs.filter((b) => b.subscription_id === this.subscription.id)
        : bs;
    });
  }

  getUserReservations() {
    return this.apiGet(API.GET_USER_RESERVATIONS);
  }

  async getUserPermission() {
    const { role, business } = this.getUserInfo();

    if (role === ROLES.TENANT_MANAGER) {
      return Object.values(PERMISSIONS);
    }
    if (role === ROLES.STORE_MANAGER && business) {
      return this.apiGet(API.USER_BUSINESS_PERMISSION);
    }
    return [];
  }

  getUserSubscriptionPermissions() {
    return this.user ? this.apiGet(API.USER_SUBSCRIPTION_PERMISSION) : [];
  }

  async setUserPermission() {
    return this.getUserPermission().then((permissions) =>
      this.setUserInfo({ permissions })
    );
  }

  selectBusiness(business) {
    this.setUserInfo({ business });
    this.setUserData({ business });
    return this.setUserPermission();
  }

  async changeBusiness(business) {
    return this.selectBusiness(business).then(() =>
      this.triggerAuthStateChanged()
    );
  }

  getBusinesses() {
    return this.subscription
      ? this.getSubscriptionBusinesses()
      : this.getTenantBusinesses();
  }

  getTenantBusinesses() {
    return this.apiGet(API.GET_TENANT_BUSINESS);
  }

  getSubscriptionBusinesses() {
    return this.apiGet(API.SUBSCRIPTIONS_BS);
  }

  getManagers() {
    return this.subscription
      ? this.getSubscriptionPermissions()
      : this.getTenantPermissions();
  }

  getTenantPermissions() {
    return this.apiGet(API.GET_TENANT_PERMISSIONS);
  }

  getSubscriptionPermissions() {
    return this.apiGet(API.SUBSCRIPTIONS_PERMISSIONS);
  }

  saveLocation(values, location) {
    return location
      ? this.apiPut(API.LOCATION, { params: { location }, body: values })
      : this.apiPost(API.BUSINESS_LOCATIONS, { body: values });
  }

  deleteLocation(location) {
    return this.apiDelete(API.LOCATION, { params: { location } });
  }

  getLocations(query = {}) {
    return this.apiGet(API.LOCATIONS, { query });
  }

  getLocationsForBusiness(businessId) {
    return this.getLocations({
      businessId: businessId || this.getUserInfo().business.id,
    });
  }

  getLocation(locationId) {
    return this.getLocations({ locationId }).then((ls = []) =>
      ls.length > 0 ? ls[0] : false
    );
  }

  saveBusinessEvent(values, event) {
    return event
      ? this.apiPut(API.BUSINESS_EVENT, { params: { event }, body: values })
      : this.apiPost(API.CREATE_BUSINESS_EVENT, { body: values });
  }

  setBusinessEventStatus(event, location_id = "", status) {
    return this.apiPut(API.EVENT_STATUS, {
      params: { event },
      body: { location_id, status },
    });
  }

  startBusinessEvent(event, location_id) {
    return this.setBusinessEventStatus(
      event,
      location_id,
      EVENT_STATUS.STARTED
    );
  }

  stopBusinessEvent(event, location_id) {
    return this.getEventCheckIns(event)
      .then((checkins) =>
        (checkins || []).length ? checkins.map((c) => c.user_id) : []
      )
      .then((users) => users.length && this.checkoutEvent(event, users))
      .then(() =>
        this.setBusinessEventStatus(event, location_id, EVENT_STATUS.ENDED)
      );
  }

  getBusinessEvents() {
    return this.apiGet(API.BUSINESS_EVENTS).then((res) => res || []);
  }

  getCampaignEvents(campaignId) {
    return this.getBusinessEvents().then((events) =>
      events.filter((e) => (e.campaign_ids || []).includes(campaignId))
    );
  }

  getCampaignUsers(campaign, businessId) {
    return this.apiGet(API.CAMPAIGN_USERS, {
      params: { campaign },
      query: { businessContext: businessId || this.getUserInfo().business.id },
    });
  }

  getEventUsers(event) {
    return this.apiGet(API.EVENT_USERS, { params: { event } });
  }

  checkin(resource_id, resource_type, users_id = []) {
    return this.apiPost(API.CHECKIN, {
      body: { resource_id, resource_type, users_id },
    });
  }

  checkinEvent(event, users_id) {
    return this.checkin(event, RESOURCE_TYPES.EVENT, users_id);
  }

  checkinLocation(location, users_id) {
    return this.checkin(location, RESOURCE_TYPES.LOCATION, users_id);
  }

  checkout(resource_id, resource_type, users_id = []) {
    return this.apiPost(API.CHECKOUT, {
      body: { resource_id, resource_type, users_id },
    });
  }

  checkoutEvent(event, users_id) {
    return this.checkout(event, RESOURCE_TYPES.EVENT, users_id);
  }

  checkoutLocation(location, users_id) {
    return this.checkout(location, RESOURCE_TYPES.LOCATION, users_id);
  }

  getEventCheckIns(businessEventId) {
    return this.apiGet(API.CHECKINS, {
      query: {
        businessEventId,
        businessId: this.getUserInfo().business.id,
      },
    });
  }

  getUserCheckIns(userId) {
    return this.apiGet(API.CHECKINS, {
      query: { userId: userId || this.getUserInfo().user_id },
    });
  }

  getLocationCheckIns(locationId) {
    return this.apiGet(API.CHECKINS, {
      query: { locationId, businessId: this.getUserInfo().business.id },
    });
  }

  revokeUserPermission(permission, email) {
    return this.apiDelete(API.USER_PERMISSION, {
      params: { permission },
    }).then((res) => {
      return email === this.getUserInfo().email
        ? this.triggerAuthStateChanged().then(() => res)
        : res;
    });
  }

  createManager(email, business_id, permission) {
    return this.apiPost(API.CREATE_MANAGER, {
      body: {
        business_id,
        client_id: this.settings.clientId,
        email: email.trim(),
        permission,
        subscription_id: this.subscription ? this.subscription.id : "",
      },
    }).then((res) => {
      return email === this.getUserInfo().email
        ? this.triggerAuthStateChanged().then(() => res)
        : res;
    });
  }

  getPolicy() {
    return this.apiGet(API.GET_TENANT_POLICY);
  }

  loadBusiness() {
    if (this.app === MANAGER) {
      return this.getUserBusiness().then((bs) => {
        const businesses = bs
          .filter((b) => b.status === 0)
          .filter(
            (b) => this.loopAvailable() || b.type !== BUSINESS_TYPES.LOOP
          );
        this.setUserInfo({ businesses });
        if (!businesses || businesses.length === 0)
          return this.selectBusiness(false);

        const prev = businesses.find(
          (b) => b.id === this.getUserData().business?.id
        );

        const first = businesses.sort((a, b) => {
          if (a.type === b.type) return a.name.localeCompare(b.name);
          return a.type === BUSINESS_TYPES.LOOP ? -1 : 1;
        })[0];

        if (!prev) return this.selectBusiness(first || false);
        this.setUserInfo({ business: prev });
        return this.setUserPermission();
      });
    }
    return this.getUserInfo();
  }

  getTransactions(query = {}) {
    return this.apiGet(API.GET_TRANSACTIONS, { query });
  }

  getTransactionsByCampaign(campaignId, query = {}) {
    return this.getTransactions({ campaignId, ...query });
  }

  getTransactionsByBusiness(businessId, query = {}) {
    return this.getTransactions({ businessId, ...query });
  }

  getCampaigns() {
    return this.apiGet(API.GET_CAMPAIGNS);
  }

  getProductInfo(code) {
    if ([TENANTS.ANCOT].includes(this.tenant)) {
      return this.apiGet(API.GET_PRODUCT_INFO_TENANT, {
        params: { "qr-code": code },
      });
    }
    return this.apiGet(API.GET_PRODUCT_INFO, { params: { "qr-code": code } });
  }

  getCampaignTerms(campaign) {
    return this.apiGet(API.GET_CAMPAIGN_TERMS, { params: { campaign } });
  }

  getCampaignReservations(campaign) {
    return this.apiGet(API.CAMPAIGN_RESERVATION, { params: { campaign } });
  }

  reserveCampaign(campaign, quantity = 1) {
    return this.apiPost(API.CAMPAIGN_RESERVATION, {
      params: { campaign },
      body: { quantity },
    });
  }

  acceptProduct(campaign) {
    return this.apiPost(API.ACCEPT_PRODUCT, { params: { campaign } });
  }

  redeemPrize(card, prize) {
    return this.apiPost(API.REDEEM_PRIZE, { params: { card, prize } });
  }

  getCampaignDetails(campaign, business) {
    return this.apiGet(API.GET_CAMPAIGN_DETAILS, {
      params: { business, campaign },
    });
  }

  getTerms() {
    return this.apiGet(API.GET_TERMS);
  }

  signTerm(terms) {
    return this.apiPut(API.SIGN_TERM, { params: { terms } });
  }

  getUsePermissionByEmail(email) {
    return this.apiPut(API.GET_USE_PERMISSION_BY_EMAIL, { body: { email } });
  }

  getRules(campaign) {
    return this.apiGet(API.GET_RULES, { params: { campaign } });
  }

  burnCoupon(code, operation_type = "", campaign_id = "") {
    return this.apiPut(API.BURN_COUPON, {
      params: { "qr-code": code },
      body: {
        business_id: this.getUserInfo().business.id,
        campaign_id,
        operation_type,
      },
    });
  }

  increaseCardBalance(code, business_id, total_value, expense, extra_points) {
    return this.apiPut(API.INCREASE_CARD_BALANCE, {
      params: { "qr-code": code },
      body: { business_id, expense, extra_points, total_value },
    });
  }

  decreaseCardBalance(code, total_value) {
    return this.apiPut(API.DECREASE_CARD_BALANCE, {
      params: { "qr-code": code },
      body: { business_id: this.getUserInfo().business.id, total_value },
    });
  }

  getContents() {
    return this.apiGet(API.GET_CONTENTS);
  }

  updatePolicy(terms, privacy, marketing, profiling, rules) {
    return this.apiPut(API.UPDATE_TENANT_POLICY, {
      body: {
        marketing_policy: marketing,
        privacy_policy: privacy,
        profiling_policy: profiling,
        rules,
        terms,
      },
    });
  }

  updateContent(content, title, description, expiration, priority) {
    return this.apiPut(API.UPDATE_CONTENT, {
      params: { content },
      body: { description, expiration, priority, title },
    });
  }

  updateContentStatus(content, status) {
    return this.apiPut(API.UPDATE_CONTENT_STATUS, {
      body: { content, status },
    });
  }

  deleteContent(content) {
    return this.apiDelete(API.DELETE_CONTENT, { params: { content } });
  }

  createOrder(values) {
    return this.apiPost(API.CREATE_ORDER, { body: values });
  }

  checkOrder(order, status) {
    return this.apiPut(API.CHECK_ORDER, { params: { order, status } });
  }

  getInventories() {
    return this.apiGet(API.GET_INVENTORIES);
  }

  uploadContentRequest(formData) {
    return this.apiGet(API.GET_UPLOAD_CONTENTS).then((res) =>
      this.apiPost(res.upload_url, { body: formData, headers: {}, json: false })
    );
  }

  getBusiness(business) {
    return this.apiGet(API.BUSINESS, { params: { business } });
  }

  deleteBusiness(business) {
    return this.apiDelete(API.BUSINESS, { params: { business } }).then(() =>
      this.triggerAuthStateChanged()
    );
  }

  saveBusiness(values, business) {
    return (business
      ? this.apiPut(API.BUSINESS, { params: { business }, body: values })
      : this.apiPost(API.CREATE_BUSINESS, {
          body: {
            ...values,
            subscription_id: this.subscription ? this.subscription.id : "",
          },
        })
    ).then(() => this.triggerAuthStateChanged());
  }

  getTenantCampaigns() {
    return this.apiGet(API.GET_TENANT_CAMPAIGNS);
  }

  queryPointsUsers(values) {
    return this.apiPost(API.QUERY_POINTS_USERS, { body: values });
  }

  getCampaign(campaign) {
    return this.plainGet(API.GET_CAMPAIGN, { params: { campaign } });
  }

  getEventsByCampaign(main) {
    return this.plainGet(API.GET_EVENTS, { query: { main } });
  }

  getEventsByBusiness(business) {
    return this.plainGet(API.GET_EVENTS, { query: { business } });
  }

  saveCampaign(values, campaign) {
    return campaign
      ? this.apiPut(API.CAMPAIGN_EDIT, { params: { campaign }, body: values })
      : this.apiPost(API.CAMPAIGN_CREATE, { body: values });
  }

  deleteCampaign(campaign) {
    return this.apiDelete(API.CAMPAIGN_EDIT, { params: { campaign } });
  }

  issue(campaign, body) {
    return this.apiPost(API.ISSUE_PRODUCT, { params: { campaign }, body });
  }

  issueCampaign(
    campaign,
    {
      user = "",
      quantity = 0,
      value = 0,
      manager_id = "",
      recharge_card = false,
    } = {}
  ) {
    return this.issue(campaign, {
      user,
      quantity,
      value,
      manager_id,
      recharge_card,
    });
  }

  acceptReservation(campaign, reservation_id) {
    return this.issue(campaign, { reservation_id });
  }

  rejectReservation(reservation) {
    return this.apiDelete(API.RESERVATION, { params: { reservation } });
  }

  getUserProducts(query) {
    return this.apiGet(API.GET_PRODUCTS, { query });
  }

  getUserProductsByStatus(status) {
    return this.getUserProducts({ status });
  }

  getUserProductsByCampaign(campaignId) {
    return this.getUserProducts({ campaignId });
  }

  getUserProductsStats() {
    return this.apiGet(API.GET_PRODUCTS_STATS);
  }

  createPermissionCode(product) {
    return this.apiGet(API.CREATE_PERMISSION, { params: { product } });
  }

  createMultiPermissionCode(products, permission_id = "") {
    return this.apiPost(API.CREATE_MULTI_PERMISSION, {
      body: { products, permission_id },
    });
  }

  requestAccountDelete() {
    return this.apiPost(API.SUPPORT_EMAIL, {
      body: {
        content: `Richiesta di oblio da parte dell'utente ${
          this.getUserInfo().token_info.email
        }`,
        reply_to: this.getUserInfo().token_info.email,
      },
    });
  }

  getExternalCode(product) {
    return this.apiGet(API.GET_EXTERNAL_CODE, { params: { product } }).then(
      ({ Code }) => Code
    );
  }

  getUser(user) {
    return this.apiGet(API.GET_USER, { params: { user } });
  }

  getUserTenantInfo(user) {
    return [TENANTS.ANCOT].includes(this.tenant) && this.isConsumer()
      ? this.apiGet(API.USER_INFO, { params: { user } })
      : {};
  }

  getMe() {
    return this.apiGet(API.ME)
      .catch(() => this.clean())
      .then((me) => {
        if (me?.role === ROLES.USER && this.isManager()) {
          throw new BmapiError(ERRORS.MANAGER_ONLY, this.settings);
        }
        this.setUserInfo({
          role: me?.role,
          email: me?.email,
          user_id: me?.id,
        });
        this.user = me;
        return me;
      });
  }

  updateLcngUser(rules, privacy, direct_marketing, indirect_marketing, user) {
    return this.apiPut(API.UPDATE_LCNG_USER, {
      params: { user },
      body: { direct_marketing, indirect_marketing, privacy, rules },
    })
      .then(() => this.loadUserData())
      .then(() => this.triggerAuthStateChanged());
  }

  refreshUserData(user) {
    return this.loadUserData(user).then(() => this.triggerAuthStateChanged());
  }

  loadUserData(user) {
    return Promise.all([this.getUser(user), this.getUserTenantInfo(user)])
      .then(([data, info]) => {
        this.userData = data;
        this.userInfo = info;
        return data;
      })
      .catch(() => this.clean());
  }

  isSubscriptionManager() {
    const perms = this.getUserInfo().subPermissions || [];
    return perms.includes(PERMISSIONS.MANAGE_SUBSCRIPTION);
  }

  canManageLoop() {
    const perms = this.getUserInfo().subPermissions || [];
    return (
      this.getUserInfo().role === ROLES.TENANT_MANAGER ||
      perms.includes(PERMISSIONS.MANAGE_SUBSCRIPTION)
    );
  }

  hasExceptions() {
    return (
      this.tenant !== TENANTS.MYSARCA ||
      this.user?.email?.includes(DOMAIN_WILDCARD)
    );
  }

  loopAvailable() {
    return (
      this.canManageLoop() &&
      (!this.subscription || this.subscription.plan_limits.loop_campaigns)
    );
  }

  canGoToAdmin() {
    const perms = this.getUserInfo().subPermissions || [];
    return (
      this.isManagerEnabled &&
      (this.getUserInfo().role === ROLES.TENANT_MANAGER ||
        perms.includes(PERMISSIONS.MANAGE_SUBSCRIPTION) ||
        perms.includes(PERMISSIONS.BUSINESS_MANAGER))
    );
  }

  upload(url, file, params, query) {
    const formData = new FormData();
    formData.append("file", file);

    return this.apiPost(url, {
      params,
      query,
      body: formData,
      headers: {},
      json: false,
    });
  }

  uploadProfilePicture(file) {
    return this.upload(API.UPLOAD_AVATAR, file)
      .then(() => this.loadUserData())
      .then(() => this.triggerAuthStateChanged());
  }

  uploadCampaignImage(campaign, file, query) {
    return this.upload(API.UPLOAD_CAMPAIGN_IMAGE, file, { campaign }, query);
  }

  uploadCampaignCover(campaign, file) {
    return this.uploadCampaignImage(campaign, file);
  }

  uploadCampaignIcon(campaign, file) {
    return this.uploadCampaignImage(campaign, file, { type: "avatar" });
  }

  subscribe(body) {
    return this.apiPost(API.SUBSCRIPTIONS, { body });
  }

  getSubscriptionStats() {
    return this.apiGet(API.SUBSCRIPTIONS_BS_STATS);
  }

  getSubscriptions(query = {}) {
    return this.apiGet(
      !this.user ? API.SUBSCRIPTIONS_PUBLIC : API.SUBSCRIPTIONS,
      { query: { ...query, tenantId: this.settings.tenantId } }
    );
  }

  getSubscriptionsById(id) {
    return this.getSubscriptions({ id });
  }

  getSubscriptionsByUser(userId) {
    return this.getSubscriptions({
      userId: userId || this.getUserInfo().user_id,
    });
  }

  getSubscriptionsBySlug(friendlyUrl = this.subscriptionSlug) {
    return this.getSubscriptions({ friendlyUrl });
  }

  doInstantPlay(product) {
    return this.apiPost(API.PLAY_IW, { params: { product } });
  }

  getLoops() {
    return this.apiGet(API.LOOPS, {
      query: this.subscription ? { subscriptionId: this.subscription.id } : {},
    });
  }

  saveLoop(values, loop) {
    const body = { ...values, subscription_id: this.subscription?.id || "" };
    return loop
      ? this.apiPut(API.LOOP, { params: { loop }, body })
      : this.apiPost(API.LOOPS, { body });
  }

  deleteLoop(loop) {
    return this.apiDelete(API.LOOP, { params: { loop } });
  }

  getLoopBusinesses(loop) {
    return this.apiGet(API.LOOP_BUSINESSES, { params: { loop } });
  }

  addLoopBusiness(business, loop) {
    return this.apiPut(API.LOOP_BUSINESS, { params: { business, loop } });
  }

  deleteLoopBusiness(business, loop) {
    return this.apiDelete(API.LOOP_BUSINESS, { params: { business, loop } });
  }

  getBanner() {
    return this.apiGet(API.BANNERS);
  }

  getWinners() {
    return this.apiGet(API.WINS);
  }

  getWinDetails(win) {
    return this.apiGet(API.WIN, { params: { win } });
  }

  setWinStatus(values, win) {
    return this.apiPut(API.WIN_STATUS, { params: { win }, body: values });
  }

  processStamp(stamp) {
    return this.apiPost(API.PROCESS_STAMP, {
      body: { stamp },
    });
  }

  getMessages() {
    return this.apiGet(API.MESSAGES);
  }

  getUnreadMessages() {
    return this.apiGet(API.MESSAGES, {
      query: { status: MESSAGE_STATUS.UNREAD },
    });
  }

  getMessage(message) {
    return this.apiGet(API.MESSAGE, { params: { message } });
  }

  markAsRead(message) {
    return this.apiPut(API.MESSAGE, {
      params: { message },
      body: { status: MESSAGE_STATUS.READ },
    });
  }

  getReimbursement(query) {
    return this.apiGet(API.TRANSACTIONS, {
      query: { reimbursement: true, ...query },
    });
  }

  getMallInventories() {
    return this.apiGet(API.MALL_INVENTORIES);
  }

  assignMallInventory(business_id, inventory_id) {
    return this.apiPut(API.MALL_INVENTORY_ASSIGN, {
      body: { business_id, inventory_id },
    });
  }

  getMallCouponStats(business) {
    return this.apiGet(API.MALL_COUPON_STATS, { params: { business } });
  }

  unJoinCampaign(campaign, business) {
    return this.apiPut(API.UNJOIN, { params: { business, campaign } });
  }
}
