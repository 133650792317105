import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { addHours, addMinutes, format, roundToNearestMinutes } from "date-fns";
import QrReader from "react-qr-reader";

import {
  Avatar,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  TextField,
} from "@material-ui/core";

import { useBmapi } from "../utils/bmapi-context";
import { getErrorMessageString } from "../utils/errors";
import { LOCATION_PREFIX } from "../utils/constants";
import { common } from "../messages";
import { CenterFocusStrong, Loyalty } from "@material-ui/icons";
import ListItemInfo from "./ListItemInfo";
import { TimePicker } from "./DatePicker";

export default function CampaignEventStart({
  campaign,
  open,
  onSuccess,
  onCancel,
}) {
  const {
    bmapi,
    notifyError,
    notifySuccess,
    startLoading,
    stopLoading,
  } = useBmapi();
  const intl = useIntl();
  const [type, setType] = useState("physical");
  const [location, setLocation] = useState("");
  const [locations, setLocations] = useState([]);
  const [from, setFrom] = useState(
    roundToNearestMinutes(new Date(), { nearestTo: 5 })
  );
  const [duration, setDuration] = useState(1);
  const [scanning, setScanning] = useState(false);
  const [partecipants, setPartecipants] = useState(false);

  const completeCreation = () => {
    const endingDate = addMinutes(
      addHours(from, duration),
      (duration % 1) * 60
    );

    return bmapi
      .saveBusinessEvent({
        business_id: bmapi.getUserInfo().business.id,
        campaign_ids: [campaign.campaign_id],
        name: new Date().toISOString().slice(0, 10),
        location_id: type === "virtual" ? location : "",
        ending_date: format(endingDate, "yyyy-MM-dd"),
        ending_time: format(endingDate, "HH:mm"),
        starting_date: format(from, "yyyy-MM-dd"),
        starting_time: format(from, "HH:mm"),
        type,
      })
      .then((event) => {
        return bmapi
          .startBusinessEvent(event.id, type === "virtual" ? "" : location)
          .then(() =>
            partecipants
              ? bmapi.checkinEvent(
                  event.id,
                  partecipants.filter((p) => p.__checked).map((p) => p.id)
                )
              : false
          );
      })
      .then(() => {
        setLocation("");
        setType("physical");
        setPartecipants(false);
        onSuccess();
        notifySuccess(
          intl.formatMessage(
            {
              id: "component.campaignEventStart.successMessage",
              defaultMessage:
                "Hai avviato con successo un evento per la campagna {name}",
            },
            {
              name: campaign.name,
            }
          )
        );
      })

      .catch((e) => notifyError(getErrorMessageString(e, intl)))
      .finally(stopLoading);
  };

  const confirmCheckins = (e) => {
    e.preventDefault();
    return completeCreation();
  };

  const handleStart = (e) => {
    e.preventDefault();
    startLoading();

    return bmapi
      .getLocationCheckIns(location)
      .then((ls) => (ls || []).filter((c) => !c.checked_out))
      .then((locationCheckins) =>
        !locationCheckins.length
          ? completeCreation()
          : bmapi.getCampaignUsers(campaign.campaign_id).then((ps) => {
              const parts = (ps || []).filter((p) =>
                locationCheckins.map((l) => l.user_id).includes(p.id)
              );
              if (parts.length) {
                return setPartecipants(
                  parts.map((p) => ({ ...p, __checked: true }))
                );
              } else {
                return completeCreation();
              }
            })
      )
      .catch((e) => notifyError(getErrorMessageString(e, intl)))
      .finally(stopLoading);
  };

  const handleCancel = (e) => {
    e.stopPropagation();
    setPartecipants(false);
    onCancel();
  };

  const handleScan = (code) => {
    if (code && code.startsWith(LOCATION_PREFIX)) {
      setLocation(code.replace(LOCATION_PREFIX, ""));
      setScanning(false);
    }
  };

  useEffect(() => {
    bmapi.getLocations().then(setLocations);
  }, [bmapi]);

  const virtualLocations = (locations || []).filter(
    (loc) => loc.type === "virtual"
  );

  const changeType = (e) => {
    setLocation(e.target.value === "virtual" ? virtualLocations[0].id : "");
    setType(e.target.value);
  };

  const handleToggle = (personId) => {
    setPartecipants((ps) =>
      ps.map((p) => ({
        ...p,
        __checked: p.id === personId ? !p.__checked : p.__checked,
      }))
    );
  };

  return (
    <Dialog onClose={handleCancel} open={open} maxWidth={"sm"} fullWidth>
      {open &&
        (scanning ? (
          <React.Fragment>
            <DialogTitle>
              <FormattedMessage
                id="component.eventStart.scannerDialogTitle"
                defaultMessage="Scansiona il codice della località"
              />
            </DialogTitle>
            <DialogContent>
              <QrReader
                onError={console.log}
                onScan={handleScan}
                style={{ maxWidth: "100%", width: "100vw" }}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setScanning(false)} color="primary">
                {intl.formatMessage(common.back)}
              </Button>
            </DialogActions>
          </React.Fragment>
        ) : partecipants && partecipants.length ? (
          <form onSubmit={confirmCheckins}>
            <DialogTitle>
              <FormattedMessage
                id="component.eventStart.confirmCheckinsTitle"
                defaultMessage="Conferma presenze"
              />
            </DialogTitle>
            <DialogContent>
              <List dense>
                {partecipants.map((p) => (
                  <ListItem key={p.id} button>
                    <ListItemAvatar>
                      <Avatar
                        alt={p.complete_name || p.email}
                        src={p.picture}
                      />
                    </ListItemAvatar>
                    <ListItemText primary={p.complete_name || p.email} />
                    <ListItemSecondaryAction>
                      <Checkbox
                        edge="end"
                        onChange={() => handleToggle(p.id)}
                        checked={p.__checked}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </DialogContent>
            <DialogActions>
              <Button type="submit" color="primary" variant="contained">
                {intl.formatMessage(common.confirm)}
              </Button>
            </DialogActions>
          </form>
        ) : (
          <form onSubmit={handleStart}>
            <DialogTitle>
              <FormattedMessage
                id="component.eventStart.startEventTitle"
                defaultMessage="Conferma avvio evento"
              />
            </DialogTitle>
            <DialogContent>
              <List>
                <ListItemInfo
                  Icon={Loyalty}
                  label={intl.formatMessage(common.campaign)}
                  text={campaign.name}
                />
                <ListItemInfo>
                  <TextField
                    margin="dense"
                    label={intl.formatMessage({
                      id: "component.eventStart.type",
                      defaultMessage: "Tipologia",
                    })}
                    value={type}
                    onChange={changeType}
                    fullWidth
                    select
                  >
                    <MenuItem value="physical">
                      {intl.formatMessage({
                        id: "component.eventStart.physical",
                        defaultMessage: "In presenza",
                      })}
                    </MenuItem>
                    <MenuItem
                      value="virtual"
                      disabled={!virtualLocations.length}
                    >
                      {intl.formatMessage({
                        id: "component.eventStart.virtual",
                        defaultMessage: "Virtuale",
                      })}
                      {!virtualLocations.length &&
                        ` [${intl.formatMessage({
                          id: "component.eventStart.noLocation",
                          defaultMessage: "Nessun luogo disponibile",
                        })}]`}
                    </MenuItem>
                  </TextField>
                </ListItemInfo>
                {type === "virtual" && (
                  <ListItemInfo>
                    <TextField
                      margin="dense"
                      label={intl.formatMessage(common.location)}
                      value={location}
                      onChange={(e) => setLocation(e.target.value)}
                      fullWidth
                      select
                      required
                    >
                      {virtualLocations.map((loc) => (
                        <MenuItem value={loc.id} key={loc.id}>
                          {loc.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </ListItemInfo>
                )}
                {type === "physical" && (
                  <ListItemInfo>
                    <TextField
                      label={intl.formatMessage(common.location)}
                      fullWidth
                      variant="filled"
                      value={location}
                      onChange={(e) => setLocation(e.target.value)}
                      required
                      autoFocus
                      margin="dense"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setScanning(true)}
                              onMouseDown={(event) => event.preventDefault()}
                            >
                              <CenterFocusStrong />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </ListItemInfo>
                )}
                <ListItemInfo>
                  <TimePicker
                    label={intl.formatMessage(common.start)}
                    value={from}
                    onChange={setFrom}
                    fullWidth
                    margin="dense"
                    autoOk
                    clearable
                    minutesStep={5}
                    openTo="minutes"
                  />
                </ListItemInfo>
                <ListItemInfo>
                  <TextField
                    label={intl.formatMessage(common.duration)}
                    value={duration}
                    onChange={(e) => setDuration(e.target.value)}
                    fullWidth
                    margin="dense"
                    select
                  >
                    {[0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4].map((option) => (
                      <MenuItem key={option} value={option}>
                        {intl.formatMessage(common.hoursCount, {
                          hours: option,
                        })}
                      </MenuItem>
                    ))}
                  </TextField>
                </ListItemInfo>
              </List>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCancel} color="primary">
                {intl.formatMessage(common.cancel)}
              </Button>
              <Button type="submit" color="primary" variant="contained">
                {intl.formatMessage(common.startEvent)}
              </Button>
            </DialogActions>
          </form>
        ))}
    </Dialog>
  );
}
