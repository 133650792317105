import React from "react";

import {
  AccountCircle as AccountCircleIcon,
  Apps,
  Assignment,
  Book,
  CenterFocusStrong as CenterFocusStrongIcon,
  CloudDownload as CloudDownloadIcon,
  ConfirmationNumber,
  EmojiEvents,
  Equalizer as EqualizerIcon,
  ExitToApp as ExitIcon,
  GroupWork,
  Home as HomeIcon,
  ListAlt,
  LiveHelp,
  Loyalty as LoyaltyIcon,
  MobileScreenShare,
  Notifications,
  OpenInNew,
  Person,
  PersonAdd,
  Room,
  School,
  Send as SendIcon,
  Settings as SettingsIcon,
  SettingsApplications,
  Store,
} from "@material-ui/icons";

import Account from "../pages/Account";
import Action from "../pages/Action";
import Activation from "../pages/Activation";
import Calendar from "../pages/consumer/Calendar";
import Campaign from "../pages/consumer/Campaign";
import CampaignManager from "../pages/manager/Campaign";
import CampaignClone from "../pages/manager/CampaignClone";
import CampaignCreate from "../pages/manager/CampaignCreate";
import CampaignEdit from "../pages/manager/CampaignEdit";
import CampaignEvents from "../pages/manager/CampaignEvents";
import Campaigns from "../pages/manager/Campaigns";
import Dashboard from "../pages/manager/Dashboard";
import Entry from "../pages/Entry";
import Friend from "../pages/consumer/Friend";
import FriendCode from "../pages/consumer/FriendCode";
import Export from "../pages/manager/Export";
import HomeConsumer from "../pages/consumer/Home";
// import HomeMananger from "../pages/manager/Home";
import Locations from "../pages/manager/Locations";
import Loops from "../pages/manager/Loops";
import ManagePrizes from "../pages/manager/ManagePrizes";
import ManageSubscription from "../pages/manager/ManageSubscription";
import Message from "../pages/Message";
import Messages from "../pages/Messages";
import Logout from "../pages/Logout";
import PasswordForget from "../pages/PasswordReset";
import Play from "../pages/consumer/Play";
import Pricing from "../pages/consumer/Pricing";
import QrCode from "../pages/consumer/QrCode";
import Reporting from "../pages/manager/Reporting";
import Reservations from "../pages/manager/Reservations";
import Checkin from "../pages/consumer/Scanner";
import Scanner from "../pages/manager/Scanner";
import StampScanner from "../pages/consumer/StampScanner";
import SendPoints from "../pages/manager/SendPoints";
import SignIn from "../pages/consumer/SignIn";
import SignInManager from "../pages/manager/SignIn";
import SignUp from "../pages/SignUp";
import Stores from "../pages/manager/Stores";
import Content from "../pages/Content";
import Test from "../pages/Test";
import Subscribe from "../pages/consumer/Subscribe";

import LuccaBuy from "../pages/custom/lcng/LuccaBuy";
import LuccaConfirm from "../pages/custom/lcng/LuccaConfirm";
import LuccaStats from "../pages/custom/lcng/LuccaStats";

import EmptyPage from "../components/EmptyPage";
import LoginPage from "../components/LoginPage";

import {
  CONSUMER_ROUTES,
  CONSUMER,
  FEATURES,
  LINK_TYPE,
  MANAGER_ROUTES,
  MANAGER,
  ROLES,
  TENANTS,
  USER_STATUS,
  SKINS,
} from "../utils/constants";
import { navigation } from "../messages";
import Lots from "../pages/manager/Lots";

export default (bmapi, app, status, baseUrl) => {
  function canView(link) {
    return (
      !link.disabled &&
      (!link.feature || bmapi.can(link.feature)) &&
      (!link.roles ||
        bmapi.validateRoles(link.roles, bmapi.getUserInfo().role)) &&
      (!link.tenants || link.tenants.includes(bmapi.tenant))
    );
  }

  const logoutLink = {
    feature: FEATURES.LOGOUT,
    menu: {
      icon: <ExitIcon />,
      label: navigation.logout,
      position: LINK_TYPE.ACTION,
      sublabel: bmapi.getUserInfo().email,
    },
    routing: {
      Component: Logout,
      path: CONSUMER_ROUTES.LOGOUT,
      Wrapper: LoginPage,
    },
  };

  const logoutLinkManager = {
    feature: FEATURES.LOGOUT,
    menu: {
      icon: <ExitIcon />,
      label: navigation.logout,
      position: LINK_TYPE.ACTION,
      sublabel: bmapi.getUserInfo().email,
    },
    routing: {
      Component: Logout,
      path: MANAGER_ROUTES.LOGOUT,
      Wrapper: LoginPage,
    },
  };

  const actionPage = {
    routing: {
      Component: Action,
      path: CONSUMER_ROUTES.ACTION,
      Wrapper: LoginPage,
    },
  };

  const campaignPage = {
    routing: {
      Component: Campaign,
      path: CONSUMER_ROUTES.CAMPAIGN,
    },
  };

  const calendarPage = {
    routing: {
      Component: Calendar,
      path: CONSUMER_ROUTES.CALENDAR,
    },
  };

  const staticPage = {
    routing: {
      Component: Content,
      path: CONSUMER_ROUTES.STATIC,
    },
  };

  const pricingPage = {
    menu: {
      icon: <Assignment />,
      label: navigation.subscribe,
    },
    routing: {
      Component: Pricing,
      path: CONSUMER_ROUTES.PRICING,
    },
    tenants: [TENANTS.SELF],
  };

  const faqPage = {
    menu: {
      icon: <LiveHelp />,
      label: navigation.faq,
      url: CONSUMER_ROUTES.STATIC.replace(":content?/", "faq"),
    },
  };

  const staticManagerPage = {
    routing: {
      Component: Content,
      path: MANAGER_ROUTES.STATIC,
    },
  };

  const test = {
    routing: {
      Component: Test,
      path: CONSUMER_ROUTES.TEST,
      Wrapper: EmptyPage,
    },
  };

  const ekomLink = {
    menu: {
      icon: <OpenInNew />,
      label: "www.ekomdiscount.it",
      url: "http://www.ekomdiscount.it/",
    },
    tenants: [TENANTS.EKOM],
  };

  const pages = {
    [CONSUMER]: {
      [USER_STATUS.ANONYMOUS]: [
        {
          routing: {
            Component: SignIn,
            path: CONSUMER_ROUTES.SIGN_IN_ACTION,
            Wrapper: LoginPage,
          },
        },
        {
          routing: {
            Component: SignIn,
            path: CONSUMER_ROUTES.SIGN_IN_EMAIL,
            Wrapper: LoginPage,
          },
        },
        {
          routing: {
            Component: SignUp,
            path: CONSUMER_ROUTES.SIGN_UP,
            Wrapper: LoginPage,
          },
        },
        {
          routing: {
            Component: PasswordForget,
            path: CONSUMER_ROUTES.PASSWORD_FORGET,
            Wrapper: LoginPage,
          },
        },
        {
          home: !bmapi.can(FEATURES.PUBLIC_HOME),
          routing: {
            Component: Entry,
            exact: true,
            path: CONSUMER_ROUTES.SIGN_IN,
            Wrapper: LoginPage,
          },
        },
        {
          feature: FEATURES.PUBLIC_HOME,
          home: true,
          routing: {
            Component: HomeConsumer,
            exact: true,
            path: CONSUMER_ROUTES.HOME,
          },
        },
        {
          feature: FEATURES.FRIEND_CODE,
          routing: {
            Component: FriendCode,
            path: CONSUMER_ROUTES.FRIEND_CODE,
            Wrapper: LoginPage,
          },
        },
        campaignPage,
        calendarPage,
        pricingPage,
        staticPage,
        actionPage,
        ekomLink,
        faqPage,
        test,
      ],
      [USER_STATUS.ACTIVATION]: [
        {
          home: true,
          routing: {
            Component: Activation,
            exact: true,
            path: CONSUMER_ROUTES.HOME,
          },
        },
        logoutLink,
        staticPage,
        actionPage,
        ekomLink,
        test,
      ],
      [USER_STATUS.LOGGED]: [
        {
          home: true,
          menu: {
            icon: <HomeIcon />,
            label: navigation.home,
          },
          routing: {
            Component: HomeConsumer,
            exact: true,
            path: CONSUMER_ROUTES.HOME,
          },
        },
        {
          routing: {
            Component: Subscribe,
            path: CONSUMER_ROUTES.JOIN,
          },
          tenants: [TENANTS.SELF],
        },
        {
          feature: FEATURES.MY_CODE,
          menu: {
            icon: <Apps />,
            label: navigation.qrcode,
          },
          routing: {
            Component: QrCode,
            path: CONSUMER_ROUTES.QR_CODE,
          },
        },
        {
          feature: FEATURES.MESSAGES,
          routing: {
            Component: Message,
            path: CONSUMER_ROUTES.MESSAGE,
          },
        },
        {
          feature: FEATURES.MESSAGES,
          menu: {
            icon: <Notifications />,
            label: navigation.notifications,
            position: LINK_TYPE.ACTION,
          },
          routing: {
            Component: Messages,
            path: CONSUMER_ROUTES.MESSAGES,
          },
        },
        {
          feature: FEATURES.CHECKIN,
          menu: {
            icon: <CenterFocusStrongIcon />,
            label: navigation.checkin,
          },
          routing: {
            Component: Checkin,
            path: CONSUMER_ROUTES.SCAN,
          },
        },
        {
          feature: FEATURES.FRIEND_CODE,
          menu: {
            icon: <PersonAdd />,
            label: navigation.friendCode,
          },
          routing: {
            Component: Friend,
            path: CONSUMER_ROUTES.FRIEND,
          },
        },
        {
          feature: FEATURES.INSTANT_WIN,
          routing: {
            Component: Play,
            path: CONSUMER_ROUTES.PLAY,
            Wrapper: EmptyPage,
          },
        },
        {
          disabled: !bmapi.canGoToAdmin(),
          feature: FEATURES.CROSS_LINKS,
          menu: {
            action: () =>
              Promise.resolve(
                (window.location.href = baseUrl + MANAGER_ROUTES.HOME.slice(1))
              ),
            icon: <SettingsApplications />,
            label: navigation.appManager,
            position: LINK_TYPE.ACTION,
          },
        },
        {
          feature: FEATURES.ACCOUNT,
          menu: {
            badge:
              bmapi.can(FEATURES.PROFILE_COMPLETE) &&
              !bmapi.userData?.profile_complete,
            icon: <Person />,
            label: navigation.account,
            position: LINK_TYPE.ACTION,
          },
          routing: {
            Component: Account,
            path: CONSUMER_ROUTES.ACCOUNT,
          },
        },
        logoutLink,
        campaignPage,
        calendarPage,
        pricingPage,
        staticPage,
        actionPage,
        ekomLink,
        test,
        {
          menu: {
            icon: <AccountCircleIcon />,
            label: navigation.campfireFaq,
            url: CONSUMER_ROUTES.STATIC.replace(":content?/", "faq"),
          },
          tenants: [TENANTS.LUCCA],
        },
        {
          routing: {
            Component: LuccaBuy,
            path: CONSUMER_ROUTES.LUCCA_BUY,
          },
          tenants: [TENANTS.LUCCA],
        },
        {
          routing: {
            Component: LuccaConfirm,
            path: CONSUMER_ROUTES.LUCCA_CONFIRM,
          },
          tenants: [TENANTS.LUCCA],
        },
        {
          feature: FEATURES.SCAN_QR_CONSUMER,
          menu: {
            icon: <CenterFocusStrongIcon />,
            label: navigation.scanner,
          },
          routing: {
            Component: StampScanner,
            path: CONSUMER_ROUTES.SCAN,
          },
        },
        faqPage,
      ],
    },
    [MANAGER]: {
      [USER_STATUS.ANONYMOUS]: [
        {
          routing: {
            Component: SignInManager,
            path: MANAGER_ROUTES.SIGN_IN_ACTION,
            Wrapper: LoginPage,
          },
        },
        {
          routing: {
            Component: SignInManager,
            path: MANAGER_ROUTES.SIGN_IN_EMAIL,
            Wrapper: LoginPage,
          },
        },
        {
          routing: {
            Component: SignUp,
            path: MANAGER_ROUTES.SIGN_UP,
            Wrapper: LoginPage,
          },
        },
        {
          routing: {
            Component: PasswordForget,
            path: MANAGER_ROUTES.PASSWORD_FORGET,
            Wrapper: LoginPage,
          },
        },
        {
          home: true,
          routing: {
            Component: Entry,
            exact: true,
            path: MANAGER_ROUTES.SIGN_IN,
            Wrapper: LoginPage,
          },
        },
        staticManagerPage,
      ],
      [USER_STATUS.ACTIVATION]: [
        {
          home: true,
          routing: {
            Component: Activation,
            exact: true,
            path: MANAGER_ROUTES.HOME,
          },
        },
        staticManagerPage,
        logoutLinkManager,
      ],
      [USER_STATUS.LOGGED]: [
        {
          home: true,
          menu: {
            icon:
              bmapi.settings.skin === SKINS.EDUCATION ? (
                <School />
              ) : (
                <LoyaltyIcon />
              ),
            label: navigation.campaigns,
          },
          routing: {
            Component: Campaigns,
            path: MANAGER_ROUTES.CAMPAIGNS,
          },
        },
        {
          feature: FEATURES.VIEW_DASHBOARD,
          menu: {
            icon: <EqualizerIcon />,
            label: navigation.dashboard,
          },
          routing: {
            Component: Dashboard,
            path: MANAGER_ROUTES.DASHBOARD,
          },
        },
        // {
        //   home: true,
        //   routing: {
        //     Component: HomeMananger,
        //     exact: true,
        //     path: MANAGER_ROUTES.HOME,
        //   },
        // },
        {
          routing: {
            Component: CampaignEvents,
            path: MANAGER_ROUTES.CAMPAIGN_EVENTS,
          },
        },
        {
          routing: {
            Component: CampaignManager,
            exact: true,
            path: MANAGER_ROUTES.CAMPAIGN,
          },
        },
        {
          feature: FEATURES.MANAGE_CAMPAIGN,
          routing: {
            Component: CampaignCreate,
            path: MANAGER_ROUTES.CREATE_CAMPAIGN,
          },
        },
        {
          feature: FEATURES.MANAGE_CAMPAIGN,
          routing: {
            Component: CampaignEdit,
            path: MANAGER_ROUTES.EDIT_CAMPAIGN,
          },
        },
        {
          feature: FEATURES.MANAGE_CAMPAIGN,
          routing: {
            Component: CampaignClone,
            path: MANAGER_ROUTES.CLONE_CAMPAIGN,
          },
        },
        {
          routing: {
            Component: Reservations,
            path: MANAGER_ROUTES.RESERVATIONS,
          },
        },
        {
          feature: FEATURES.SCAN_QR,
          menu: {
            icon: <CenterFocusStrongIcon />,
            label: navigation.scanner,
          },
          routing: {
            Component: Scanner,
            path: MANAGER_ROUTES.SCAN,
          },
        },
        {
          feature: FEATURES.SEND_POINTS,
          menu: {
            icon: <SendIcon />,
            label: navigation.sendPoints,
          },
          routing: {
            Component: SendPoints,
            path: MANAGER_ROUTES.SEND_POINTS,
          },
        },
        {
          menu: {
            label: navigation.campfireStats,
          },
          routing: {
            Component: LuccaStats,
            path: MANAGER_ROUTES.LUCCA_STATS,
          },
          tenants: [TENANTS.LUCCA],
        },
        {
          disabled: !bmapi.canManageLoop() || !bmapi.hasExceptions(),
          menu: {
            icon:
              bmapi.settings.skin === SKINS.EDUCATION ? <Book /> : <Store />,
            label: navigation.stores,
          },
          routing: {
            Component: Stores,
            path: MANAGER_ROUTES.STORES,
          },
        },
        {
          disabled: !bmapi.canManageLoop(),
          feature: FEATURES.LOOPS,
          menu: {
            icon: <GroupWork />,
            label: navigation.loops,
          },
          routing: {
            Component: Loops,
            path: MANAGER_ROUTES.LOOPS,
          },
        },
        {
          disabled: !bmapi.canManageLoop(),
          feature: FEATURES.LOCATIONS,
          menu: {
            icon: <Room />,
            label: navigation.locations,
          },
          routing: {
            Component: Locations,
            path: MANAGER_ROUTES.LOCATIONS,
          },
        },
        {
          feature: FEATURES.REPORTING,
          menu: {
            icon: <ListAlt />,
            label: navigation.reporting,
          },
          routing: {
            Component: Reporting,
            path: MANAGER_ROUTES.REPORTING,
          },
        },
        {
          feature: FEATURES.EXPORT,
          menu: {
            icon: <CloudDownloadIcon />,
            label: navigation.export,
          },
          routing: {
            Component: Export,
            path: MANAGER_ROUTES.EXPORT,
          },
        },
        {
          disabled: !bmapi.isSubscriptionManager(),
          menu: {
            icon: <SettingsIcon />,
            label: navigation.subscriptionSettings,
          },
          routing: {
            Component: ManageSubscription,
            path: MANAGER_ROUTES.SUBSCRIPTION,
          },
        },
        {
          feature: FEATURES.INSTANT_WIN,
          menu: {
            icon: <EmojiEvents />,
            label: navigation.prizes,
          },
          roles: ROLES.TENANT_MANAGER,
          routing: {
            Component: ManagePrizes,
            path: MANAGER_ROUTES.PRIZES,
          },
        },
        {
          feature: FEATURES.LOTS,
          menu: {
            icon: <ConfirmationNumber />,
            label: navigation.lots,
          },
          roles: ROLES.TENANT_MANAGER,
          routing: {
            Component: Lots,
            path: MANAGER_ROUTES.LOTS,
          },
        },
        {
          disabled: !bmapi.isConsumerEnabled,
          feature: FEATURES.CROSS_LINKS,
          menu: {
            action: () =>
              Promise.resolve(
                (window.location.href = baseUrl + CONSUMER_ROUTES.HOME.slice(1))
              ),
            icon: <MobileScreenShare />,
            label: navigation.appConsumer,
            position: LINK_TYPE.ACTION,
          },
        },
        {
          menu: {
            icon: <Person />,
            label: navigation.account,
            position: LINK_TYPE.ACTION,
          },
          routing: {
            Component: Account,
            path: MANAGER_ROUTES.ACCOUNT,
          },
        },
        staticManagerPage,
        logoutLinkManager,
      ],
    },
  };

  return pages[app || bmapi.app][status || bmapi.userStatus].filter(canView);
};
